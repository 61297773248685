import { Fonts } from '@/styles/fonts';
import { OpenSansFont, NotoSansFont } from '@/styles/fonts';
import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    font?: Fonts;
    isCentered?: boolean;
    className?: string;
};

const HNText = ({ children, font = Fonts.OpenSans, isCentered = false, className }: Props) => {
    const fontClassName = font === Fonts.OpenSans ? OpenSansFont.className : NotoSansFont.className;

    return (
        <div
            dir="rtl"
            className={`${fontClassName} ${className}`}
            style={{
                textAlign: isCentered ? 'center' : 'start'
            }}
        >
            {children}
        </div>
    );
};

export default HNText;
