type TEnv = 'development' | 'test' | 'staging' | 'production';

const canvasWidth = 500;
const canvasHeight = (canvasWidth * 7) / 5;
const previewWidth = 300;
const previewHeight = (previewWidth * 7) / 5;
const printWidth = 1500;
const printwHeight = (printWidth * 7) / 5;
const env = (process.env.HAZMANINA_ENV as TEnv) || 'development';
const isDevelopmentEnv = env === 'development' || env === 'staging';
const isProductionEnv = env === 'production';
const isTestEnv = env === 'test';
const isApolloStudioEnabled = isDevelopmentEnv;
const gqlRateLimitPeriodSeconds = Number(process.env.GQL_RATE_LIMIT_PERIOD_SECONDS) || 60;
const gqlRateLimitMaxRequests = Number(process.env.GQL_RATE_LIMIT_MAX_REQUESTS) || 100;
const isAuthDisabled = !isProductionEnv;
const googleClientId = process.env.GOOGLE_CLIENT_ID || '';
const googleClientSecret = process.env.GOOGLE_CLIENT_SECRET || '';
const facebookClientId = process.env.FACEBOOK_CLIENT_ID || '';
const facebookClientSecret = process.env.FACEBOOK_CLIENT_SECRET || '';
const mongoDbHashKey = process.env.MONGODB_HASH_KEY || 'key';
const googleTemplatesStorageUrl = process.env.GOOGLE_TEMPLATES_CDN || 'https://templates.hazmanina.co.il';
const fontsBaseUrl = `${googleTemplatesStorageUrl}/fonts`;
const backgroundBaseUrl = `${googleTemplatesStorageUrl}/backgrounds`;
const templatePreviewBaseUrl = `${googleTemplatesStorageUrl}/preview`;
const templatesBaseUrl = `${googleTemplatesStorageUrl}/model/templates`;
const projectPreviewsBucketName = isDevelopmentEnv ? 'hazmanina-projects-staging' : 'hazmanina-projects';
const templateBucketName = 'hazmanina-templates';
const googleProjectsPreviewStorageUrl =
    process.env.GSTORAGE_PROJECTS_BUCKET_URL || 'https://storage.googleapis.com/hazmanina-projects-staging';
const projectPreviewBaseUrl = `${googleProjectsPreviewStorageUrl}/previews`;

const config = {
    canvas: {
        width: canvasWidth,
        height: canvasHeight
    },
    print: {
        width: printWidth,
        height: printwHeight
    },
    preview: {
        width: previewWidth,
        height: previewHeight
    },
    watermark: {
        color: 'red',
        width: canvasWidth / 500
    },
    text: {
        color: 'black',
        hoverColor: 'gray'
    },
    env,
    isProductionEnv,
    isDevelopmentEnv,
    isTestEnv,
    isApolloStudioEnabled,
    database: {
        dbName: 'hazmanina'
    },
    gqlRateLimitPeriodSeconds,
    gqlRateLimitMaxRequests,
    isAuthDisabled,
    googleLogin: {
        clientId: googleClientId,
        secret: googleClientSecret
    },
    facebookLogin: {
        clientId: facebookClientId,
        secret: facebookClientSecret
    },
    mongoDbHashKey,
    templates: {
        bucketName: templateBucketName,
        fontsBaseUrl,
        backgroundBaseUrl,
        templatesBaseUrl,
        templatePreviewBaseUrl,
        storageUrl: googleTemplatesStorageUrl
    },
    projectsPreviewsBucketName: projectPreviewsBucketName,
    projectPreviewBaseUrl
};

export default config;
