import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import getConfig from 'next/config';
import { useMemo } from 'react';
import { signOut } from 'next-auth/react';
import logger from './utils/logger';

export default function useApollo() {
    return useMemo(() => createApolloClient(), []);
}

const createApolloClient = () => {
    const { publicRuntimeConfig } = getConfig();
    // Log any GraphQL errors or network error that occurred
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path, extensions: { code } }) => {
                logger.error(
                    {
                        message,
                        locations,
                        path
                    },
                    `[GraphQL error] ${message}`
                );
                if (code === 'UNAUTHENTICATED') {
                    // redirect to login page
                    signOut();
                }
            });

        if (networkError) logger.error(`[Network error]: ${networkError}`);
    });

    const httpLink = createHttpLink({
        uri: '/api/graphql'
    });

    const link = ApolloLink.from([errorLink, httpLink]);

    const cache = new InMemoryCache();

    return new ApolloClient({
        uri: `${publicRuntimeConfig.serverBaseUrl}/api/graphql`,
        cache,
        link,
        connectToDevTools: true
    });
};
