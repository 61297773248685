import React from 'react';
import { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';
import useApollo from '@/apollo-client';
import NavigationBar from '@/components/NavigationBar';
import { GlobalStyles } from '@/styles/globals';
import Head from 'next/head';
import { styled } from 'styled-components';
import { NextUIProvider } from '@nextui-org/system';
import '@/styles/tailwind.css';
import BottomBar from '@/components/BottomBar';

const MyApp: React.FC<AppProps> = ({ Component, pageProps: { session, ...pageProps } }: AppProps) => {
    const apolloClient = useApollo();

    return (
        <>
            <Head>
                <title>הזמנינא</title>
                <link rel="icon" href="/smiling-letter.png" />
            </Head>
            <GlobalStyles />
            <SessionProvider session={session} refetchInterval={30 * 60} refetchOnWindowFocus={true}>
                <ApolloProvider client={apolloClient}>
                    <NextUIProvider>
                        <Container className="dark">
                            <InnerContainer>
                                <NavigationBar />
                                <ComponentContainer>
                                    <Component {...pageProps} />
                                </ComponentContainer>
                                <BottomBar />
                            </InnerContainer>
                        </Container>
                    </NextUIProvider>
                </ApolloProvider>
            </SessionProvider>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const InnerContainer = styled.div`
    width: min(100vw, 1800px);
    min-height: 100vh;
`;

const ComponentContainer = styled.div`
    height: calc(100% - 200px - 70px);
`;

export default MyApp;
